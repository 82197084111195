<template>
  <component :is="billData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="billData === undefined">
      <h4 class="alert-heading">{{ $t("message.errorLoadingData") }}</h4>
      <div class="alert-body">
        {{ $t("message.bills.billNotFound") }}
        <b-link class="alert-link" :to="{ name: 'apps-bill-list' }">
          {{ $t("message.bills.billList") }}
        </b-link>
        {{ $t("message.bills.forOtherBills") }}
      </div>
    </b-alert>

    <template v-if="billData">
      <div>
        <b-card>
          <h2 class="mb-3">{{ $t("message.bills.billsEdit") }}</h2>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form @submit.prevent="handleSubmit(updateBills())">
              <b-row>
                <b-col md="6">
                  <b-form-group label-for="typeBill">
                    <template v-slot:label>
                      {{ $t("message.tasks.type") }}
                    </template>
                    <v-select
                      :on-change="selectedType()"
                      v-model="billData.type"
                      :options="typeOptions"
                      :reduce="(val) => val.value"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6" v-if="activeDate">
                  <b-form-group label-for="expiryDate">
                    <template v-slot:label>
                      {{ $t("message.bills.expiryDate") }}
                    </template>

                    <flat-pickr
                      id="expiry-date"
                      v-model="billData.expiryDate"
                      class="mt-0 form-control"
                      :config="flatConfig"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6" v-if="activeDay">
                  <b-form-group label-for="expiryDate">
                    <template v-slot:label>
                      {{ $t("message.bills.expiryDate") }}
                    </template>
                    <flat-pickr
                      id="expiry-date"
                      v-model="billData.expiryDay"
                      class="mt-0 form-control"
                      :config="flatConfigDay"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- text area -->
                <b-col md="12">
                  <validation-provider name="title" rules="required">
                    <b-form-group label-for="titleBill">
                      <template v-slot:label>
                        {{ $t("message.projects.description") }}
                      </template>

                      <quill-editor
                        id="title"
                        style="margin-bottom: 52px; height: 200px"
                        :options="editorOption"
                        v-model="billData.title"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </div>
    </template>

    <div class="text-right">
      <b-button
        @click="updateBills()"
        variant="primary"
        :to="{ name: 'apps-bill-list' }"
      >
        {{ $t("message.buttons.save") }}
      </b-button>
    </div>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BFormTextarea,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import router from "@/router";
import vSelect from "vue-select";
import dateInputEdit from "@/@core/utils/dateInputEdit";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    flatPickr,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },

  data() {
    return {
      activeDate: false,
      activeDay: false,

      typeOptions: [
        { label: "Isolado", value: "isolate" },
        { label: "Recorrente", value: "recurrent" },
      ],

      flatConfig: { altFormat: "d/m/Y", altInput: true, format: "d/m/Y" },

      flatConfigDay: {
        dateFormat: "j",
      },

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      userData: store.state.user.userData,
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInputEdit(newVal);
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInputEdit(this.$i18n.locale);
    }
  },

  methods: {
    updateBills() {
      if (this.billData.type === "isolate") {
        this.billData.start = this.billData.expiryDate;
        this.billData.end = this.billData.expiryDate;
      }

      axios({
        method: "put",
        url: `${URL_API}bill/${this.billData.id}`,
        headers: getHeader(this.userData),
        data: this.billData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updatedB"),
          },
        });
      });
    },

    selectedType() {
      if (this.billData.type === "isolate") {
        this.activeDate = true;
        this.activeDay = false;
      } else {
        this.activeDate = false;
        this.activeDay = true;
      }
    },
  },
  setup() {
    const billData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}bill/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        billData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          billData.value = undefined;
        }
      });

    return {
      billData,
    };
  },
};
</script>

<style>
.mtn {
  margin-top: -0.7rem;
}
</style>
